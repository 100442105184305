export const sanatizeBooleans = (query) => {
  // return query.replace(
  //   /\b(not|and|or)\b(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/gi,
  //   (match) => match.toUpperCase()
  // );
  // Regex pattern for finding AND, OR, NOT outside double quotes
  const regex = /\b(and|or|not)\b(?=(?:[^"]*"[^"]*")*[^"]*$)/gi;

  // Replace matched boolean operators with uppercase
  query = query.replace(regex, (match) => match.toUpperCase());

  // Ensure a space after each boolean operator when followed by '('
  query = query.replace(/\b(AND|OR|NOT)\s*\(/g, "$1 (");

  // Ensure a space after a closing parenthesis if followed by a boolean operator
  query = query.replace(/\)(AND|OR|NOT)\b/g, ") $1");

  query = query.replace(/“|”/gi, '"');

  return query;
};
export const addPrefix = (input, prefix) => {
  // Regular expression to match quoted and unquoted segments separately
  const regex = /"[^"]+"|\S+/g;
  input = input.replace(/title:\(/gi, "");
  return input.replace(regex, (match) => {
    // If the match is quoted, return it as-is with the prefix for the whole quoted string
    if (match.startsWith('"') && match.endsWith('"')) {
      return prefix + match;
    }
    // Skip boolean operators
    if (["AND", "OR", "NOT"].includes(match)) {
      return match;
    }
    // Add prefix to individual words
    return prefix + match;
  });
};
